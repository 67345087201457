import ModalWithButton from "../../modal/ModalWithButton";
import FormWithSubmit from "../../form/FormWithSubmit";
import apiPost from "../../../api/apiPost";
import getColumnDefinitions from "../../../services/getColumnDefinitions";
import { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TabsWrapper from "../../tabs/tabsWrapper";
import { useContext } from "react";
import { DataContext } from "../../../contexts/DataContext";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import toast from "react-hot-toast";

const RequiredFieldsForm = ({
  onAdd,
  model,
  interactionsValues,
  defaultValues,
  handleChange,
  noTemplates,
  parentModel,
  columnDefinitionsHandler,
  presetValues,
  company,
  columns,
  onClose,
  eventId,
}) => {
  function filterDataByFields(data, fields) {
    const filteredData = {};

    const fieldsMap = fields.reduce((acc, field) => {
      if (!acc[field.model]) {
        acc[field.model] = [];
      }
      acc[field.model].push(field.field);
      return acc;
    }, {});

    for (const model in data) {
      if (data.hasOwnProperty(model)) {
        if (fieldsMap[model]) {
          fieldsMap[model].forEach((field) => {
            if (data[model].hasOwnProperty(field)) {
              filteredData[field] = data[model][field];
            }
          });
        }
      }
    }

    return filteredData;
  }

  const resultValues = filterDataByFields(defaultValues, columns);

  function buildDataObject(columns, values) {
    const data = {};

    values.forEach(({ key, value }) => {
      const column = columns.find((col) => col.field === key);

      if (column) {
        const model = column.model;

        if (!data[model]) {
          data[model] = {};
        }

        data[model][key] = value;
      }
    });

    return data;
  }

  const handleAdd = async (values) => {
    const formValues = buildDataObject(columns, values);

    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/save-required-fields",
        {
          formValues,
          interactionsValues,
          eventId,
        }
      );
      if (res.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      onAdd(res.data);
      toast.success("Osoba přidána");
    } catch (err) {
      console.error(err);
    }
  };
  const { data: contextData } = useContext(DataContext);

  const [columnDefinitions, setColumnDefinitions] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const policyColumns = JSON.parse(localStorage.getItem("policy_column_tree"));

  console.log({ columns });

  useEffect(() => {
    let mounted = true;

    if (!columnDefinitions) {
      getColumnDefinitions(
        t,
        model,
        contextData,
        true,
        (definitions) =>
          definitions.filter(
            (def) => !presetValues?.find((val) => def.name === val.key)
          ),
        columns
      ).then((definitions) => {
        if (columnDefinitionsHandler) {
          definitions = columnDefinitionsHandler(definitions);
        }
        for (const key of Object.keys(definitions["default"])) {
          if (definitions["default"][key].name === "all_customers") {
            definitions["default"][key].data_array = contextData.all_customers;
          }
        }

        definitions.default = definitions.default.map((def) => {
          return {
            ...def,
            isDisabled: () => {
              let column = columns.find((col) => col.field == def.name);
              return !policyColumns[column.model].update.includes(def.name);
            },
          };
        });

        if (mounted) {
          setColumnDefinitions(definitions);
          setIsLoading(false);
        }
      });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [model, t, columnDefinitions]);

  if (isLoading) {
    return <LinearProgress sx={{ minWidth: "200px" }} />;
  }

  return (
    <FormWithSubmit
      onClose={onClose}
      company={company}
      parentModel={parentModel}
      form={columnDefinitions["default"]}
      onSubmit={handleAdd}
      handleChange={handleChange}
      defaultValues={resultValues}
      submitText="Odeslat"
      model={model}
      noTemplates={noTemplates}
    />
  );
};
export default RequiredFieldsForm;
